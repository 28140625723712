import React, { Component } from "react";
import { Col } from "react-bootstrap";

class Category extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Col md="auto" style={{ marginTop: 0 }}>
          <figure className="figure">
            <img
              src={this.props.data.url}
              className="figure-img img-fluid rounded"
              alt="A generic square placeholder image with rounded corners in a figure."
            />
            <figcaption className="figure-caption">
              {this.props.data.content}
            </figcaption>
          </figure>
        </Col>
      </React.Fragment>
    );
  }
}

export default Category;
