import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Pro from "./Pro";
import HomeScreen from "./HomeScreen";

class App extends Component {
  state = {};
  render() {
    return (
      <BrowserRouter>
       <div>
        <Switch>
          <Route path="/pro" render={(props) => <Pro {...props}/>}/>
          <Route path="/">
            <HomeScreen />
          </Route>
        </Switch>
      </div>
      </BrowserRouter>
    );
  }
}

export default App;
