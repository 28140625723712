import React, { Component } from 'react';

class SimilarProductList extends Component {
    state = {  }
    render() { 
        return ( <React.Fragment>
           
            <h4>prabhakar</h4>
            
        </React.Fragment> );
    }
}
 
export default SimilarProductList;