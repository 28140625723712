import React, { Component } from "react";
import Navbar from "./Navbar";
import CategoryList from "./CategoryList";
import ProductList from "./ProductList";

class HomeScreen extends Component {
    state = {
        list: [
          {
            desc: "Deals of the day",
            products: [
              {
                id: 1,
                url: "https://source.unsplash.com/random/180x120?sig=1",
                desc: "Jean1",
              },
              {
                id: 2,
                url: "https://source.unsplash.com/random/180x120?sig=2",
                desc: "Jean2",
              },
              {
                id: 3,
                url: "https://source.unsplash.com/random/180x120?sig=3",
                desc: "Jean3",
              },
              {
                id: 4,
                url: "https://source.unsplash.com/random/180x120?sig=4",
                desc: "Jean4",
              },
              {
                id: 5,
                url: "https://source.unsplash.com/random/180x120?sig=5",
                desc: "Jean5",
              },
              {
                id: 6,
                url: "https://source.unsplash.com/random/180x120?sig=6",
                desc: "Jean6",
              },
              {
                id: 7,
                url: "https://source.unsplash.com/random/180x120?sig=5",
                desc: "Jean5",
              },
              {
                id: 8,
                url: "https://source.unsplash.com/random/180x120?sig=6",
                desc: "Jean6",
              },
            ],
          },
          {
            desc: "Men's Footwear",
            products: [
              {
                id: 1,
                url: "https://source.unsplash.com/random/180x120?sig=7",
                desc: "Foot1",
              },
              {
                id: 2,
                url: "https://source.unsplash.com/random/180x120?sig=8",
                desc: "Foot2",
              },
              {
                id: 3,
                url: "https://source.unsplash.com/random/180x120?sig=9",
                desc: "Foot3",
              },
              {
                id: 4,
                url: "https://source.unsplash.com/random/180x120?sig=10",
                desc: "Foot4",
              },
              {
                id: 5,
                url: "https://source.unsplash.com/random/180x120?sig=11",
                desc: "Foot5",
              },
              {
                id: 6,
                url: "https://source.unsplash.com/random/180x120?sig=12",
                desc: "Foot6",
              },
              {
                id: 7,
                url: "https://source.unsplash.com/random/180x120?sig=11",
                desc: "Foot5",
              },
              {
                id: 8,
                url: "https://source.unsplash.com/random/180x120?sig=12",
                desc: "Foot6",
              },
            ],
          },
          {
            desc: "Trending Offers",
            products: [
              {
                id: 1,
                url: "https://source.unsplash.com/random/180x120?sig=7",
                desc: "Foot1",
              },
              {
                id: 2,
                url: "https://source.unsplash.com/random/180x120?sig=8",
                desc: "Foot2",
              },
              {
                id: 3,
                url: "https://source.unsplash.com/random/180x120?sig=9",
                desc: "Foot3",
              },
              {
                id: 4,
                url: "https://source.unsplash.com/random/180x120?sig=10",
                desc: "Foot4",
              },
              {
                id: 5,
                url: "https://source.unsplash.com/random/180x120?sig=11",
                desc: "Foot5",
              },
              {
                id: 6,
                url: "https://source.unsplash.com/random/180x120?sig=12",
                desc: "Foot6",
              },
              {
                id: 7,
                url: "https://source.unsplash.com/random/180x120?sig=11",
                desc: "Foot5",
              },
              {
                id: 8,
                url: "https://source.unsplash.com/random/180x120?sig=12",
                desc: "Foot6",
              },
            ],
          },
        ],
      };
  render() {
    return (
      <React.Fragment>
        <Navbar></Navbar>
        <CategoryList></CategoryList>
        {this.state.list.map((productList) => (
          <ProductList
            key={productList.desc}
            productList={productList}
          ></ProductList>
        ))}
      </React.Fragment>
    );
  }
}

export default HomeScreen;
