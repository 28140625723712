import React, { Component } from "react";
import FilterScreen from "./FilterScreen";
import SimilarProductList from "./SimilarProductList";

class SimilarProductScreen extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <section style={{ marginTop: "5px" }}>
          <nav
            style={{
              float: "left",
              width: "18%",
              padding: "20px",
              background: "#fff",
              marginLeft: "0.1%",
            }}
          >
            <FilterScreen categories={this.props.categories}></FilterScreen>
          </nav>
          <div
            className="border"
            style={{
              float: "left",
              width: "80.5%",
              padding: "20px",
              background: "#f1f1f1",
              marginLeft: "0.5%",
            }}
          >
            <SimilarProductList></SimilarProductList>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default SimilarProductScreen;
