import React, {Component } from 'react';
import { Button ,Modal,Form } from 'react-bootstrap';

class SignupScreen extends Component {
    state = { 
        show:false,
        name:'',
        emailAddress:'',
        Password:''
    }

    styles ={
     
    }
  
    handleClose = () => {
        this.setState({show:false})
    }
    handleShow = () => {
        this.setState({show:true})
    }
    handleSubmit = event => {
        this.setState({show:false})
        alert('A name was submitted: ' + this.state.name + 'Email: ' + this.state.emailAddress + 'Password: ' +this.state.Password);
        event.preventDefault();
      }

    render() { 
        return (<React.Fragment>
        <Button style={{ marginLeft: '.5rem' }} variant="primary" onClick={this.handleShow}>
          Sign up
        </Button>
  
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Sign up</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form.Group>
              <Form.Label>Name:{this.props.name}</Form.Label>
              <Form.Control type="text" placeholder="Enter Name"  onChange={e=>this.setState({name:e.target.value})}/>    
              <Form.Label>Email Address: </Form.Label>
              <Form.Control type="text" placeholder="Enter email"  onChange={e=>this.setState({emailAddress:e.target.value})}/>
              <Form.Label>Password: </Form.Label>
              <Form.Control type="password" placeholder="Enter Password" onChange={e=>this.setState({Password:e.target.value})}/>
          </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleSubmit}>
              Register
            </Button>
          </Modal.Footer>
        </Modal>
        </React.Fragment> );
    }
}
 
export default SignupScreen;