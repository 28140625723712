import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Signup from "./Signup";
import Login from "./Login";
import Cart from "./Cart"

class Navbar extends Component {
  state = {
    name:'Prabhakar'
  };
  render() {
    let name = this.state.name;
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-md navbar-light bg-light">
          <a href="#" className="navbar-brand">
            Brand
          </a>
          <button
            type="button"
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav">
              <a href="#" className="nav-item nav-link active">
                Home
              </a>
              <a href="#" className="nav-item nav-link">
                Profile
              </a>
              <a href="#" className="nav-item nav-link">
                Messages
              </a>
              <a href="#" className="nav-item nav-link disabled" tabIndex="-1">
                Reports
              </a>
            </div>
            <form className="col-md-4">
              <div className="input-group">
                <input type="text" className="form-control" placeholder="Search" />
                <div className="input-group-append">
                  <button className="btn btn-secondary">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </form>
            <div className="navbar-nav ml-auto">
             <Login name={name}></Login>
             <Signup name={name}></Signup>
             <Cart></Cart>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default Navbar;
