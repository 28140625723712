import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Product from "./Product";

class ProductList extends Component {
  state = {
  };
  render() {
    return (
      <React.Fragment>
        <div className="border border-white">
        <div className="row border p-3 mb-5 rounded" style={{marginTop:"0px"}}>
          <div className="col">
            <h4>{this.props.productList.desc}</h4>
          </div>
          <div className="col">
            <button type="button" className="btn btn-primary float-right">
              View All
            </button>
          </div>
        </div>
        <div className="row border p-3 mb-5 rounded" style={{ height: "250px",marginTop:"-50px"}}>
          {/* <Container> */}
          <Row>
            {this.props.productList.products.map((val) => (
              <Product key={val.id} products={val}></Product>
            ))}
          </Row>
          {/* </Container> */}
        </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProductList;
