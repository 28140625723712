import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";

class Product extends Component {
  state = {
    redirect: false,
    categories:["Clothing","Jewellery"],
  };

  handleOnClick = () => {
    this.setState({ redirect: true });
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          
          to={{
            pathname: "/pro",
            state:{ categories:this.state.categories},
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Col
          className="shadow p-3 mb-5 rounded"
          md="auto"
          onClick={this.handleOnClick}
        >
          <figure className="figure">
            <img
              src={this.props.products.url}
              className="figure-img img-fluid rounded"
              alt="A generic square placeholder image with rounded corners in a figure."
            />
            <figcaption
              className="figure-caption text-dark"
              style={{ textAlign: "center" }}
            >
              {this.props.products.desc}
            </figcaption>
            <h6 className="text-success" style={{ textAlign: "center" }}>
              From $56
            </h6>
          </figure>
        </Col>
      </React.Fragment>
    );
  }
}

export default Product;
