import React, { useState } from "react";
import { Button ,Modal,Form } from 'react-bootstrap';
import "../css/login.css";

function Login(props) {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <Button variant="primary" onClick={handleShow}>
          Login
        </Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Login</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form.Group >
              <Form.Label>Name:{props.name}</Form.Label>
              <Form.Control type="text" placeholder="Enter Name"/>    
              <Form.Label>Password: </Form.Label>
              <Form.Control type="password" placeholder="Enter Password"/>
          </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Sign in
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  

  export default Login;
