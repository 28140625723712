import React, { Component } from "react";
import { Button, Modal, Form } from "react-bootstrap";

class Cart extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Button style={{ marginLeft: "2rem" }} variant="primary">
          Cart
          <i className="fa fa-shopping-cart" style={{ fontsize: "24px"}}></i>
        </Button>
      </React.Fragment>
    );
  }
}

export default Cart;
