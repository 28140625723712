import React, { Component } from "react";

class FilterComponent extends Component {
  state = {
    show: false,
    display: "none",
    transform: "rotate(45deg)",
    WebkitTransform: "rotate(45deg)",
  };
  toggleDiv = () => {
    if (this.state.show) {
      this.setState({ show: false });
      this.setState({ display: "none" });
      this.setState({ transform: "rotate(45deg)" });
      this.setState({ WebkitTransform: "rotate(45deg)" });
    } else {
      this.setState({ show: true });
      this.setState({ display: "block" });
      this.setState({ transform: "rotate(-135deg)" });
      this.setState({ WebkitTransform: "rotate(-135deg)" });
    }
  };
  render() {
    return (
      <React.Fragment>
        <div>
          <div className="row" onClick={this.toggleDiv}>
            <div className="col">
              <span>{this.props.comp.desc}</span>
            </div>
            <div className="col">
              <i
                style={{
                  display: "inline-block",
                  border: "solid black",
                  borderWidth: "0 3px 3px 0",
                  transform: this.state.transform,
                  WebkitTransform: this.state.WebkitTransform,
                  padding: "3px",
                  float: "right",
                  marginTop:"8px"
                }}
              ></i>
            </div>
          </div>
          <div style={{ display: this.state.display }}>
            {this.props.comp.checkBoxes.map((val) => (
              <div key={val.id} className="form-check">
                <input
                  onChange={this.props.addSelected}
                  type="checkbox"
                  id={this.props.comp.key}
                  value={val.id}
                  className="form-check-input"
                  checked={val.checked}
                ></input>
                <label className="form-check-label">{val.name}</label>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FilterComponent;
