import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Category from "./Category";

class CategoryList extends Component {
  state = {
    data: [
      {
        id: 1,
        content: "Top Offers",
        url: "https://source.unsplash.com/random/75x75?sig=1",
      },
      {
        id: 2,
        content: "Grocery",
        url: "https://source.unsplash.com/random/75x75?sig=2",
      },
      {
        id: 3,
        content: "Mobiles",
        url: "https://source.unsplash.com/random/75x75?sig=3",
      },
      {
        id: 4,
        content: "Fashion",
        url: "https://source.unsplash.com/random/75x75?sig=4",
      },
      {
        id: 5,
        content: "Electronics",
        url: "https://source.unsplash.com/random/75x75?sig=5",
      },
      {
        id: 6,
        content: "Appliances",
        url: "https://source.unsplash.com/random/75x75?sig=6",
      },
      {
        id: 7,
        content: "Home",
        url: "https://source.unsplash.com/random/75x75?sig=7",
      },
      {
        id: 8,
        content: "Travel",
        url: "https://source.unsplash.com/random/75x75?sig=8",
      },
      {
        id: 9,
        content: "Beauty",
        url: "https://source.unsplash.com/random/75x75?sig=9",
      },
      {
        id: 10,
        content: "Sports",
        url: "https://source.unsplash.com/random/75x75?sig=10",
      }
    ]
  };
  render() {
    return (
      <React.Fragment>
        <div
          className="row shadow p-3 mb-5 rounded"
          style={{ height: "130px" }}
        >
          <Container>
            <Row>
              {this.state.data.map((val) => (
                <Category key={val.id} data={val}></Category>
              ))}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default CategoryList;
