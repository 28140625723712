import React, { Component } from 'react';
import Navbar from "./Navbar";
import SimilarProductScreen from "./SimilarProductScreen";

class Pro extends Component {
    state = {  }
    // componentDidMount(props){
    //     console.log("categories",this.props.location.state.categories);
    // }
    render() { 
        return (<React.Fragment>
            <Navbar></Navbar>
            <SimilarProductScreen categories={this.props.location.state.categories}></SimilarProductScreen>
        </React.Fragment>  );
    }
}
 
export default Pro;