import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import FilterComponent from "./FilterComponent";

class FilterScreen extends Component {
  state = {
    filterComponents: [
      {
        key: 1,
        desc: "Brand",
        checkBoxes: [
          { id: 1, name: "Puma", checked: false },
          { id: 2, name: "Levis", checked: false },
          { id: 3, name: "Wrangler", checked: false },
          { id: 4, name: "Adidas", checked: false },
          { id: 5, name: "Nike", checked: false },
        ],
      },
      {
        key: 2,
        desc: "Style",
        checkBoxes: [
          { id: 1, name: "Modern", checked: false },
          { id: 2, name: "Classic", checked: false },
          { id: 3, name: "Old", checked: false },
          { id: 4, name: "Vintage", checked: false },
          { id: 5, name: "Mass", checked: false },
        ],
      },
      {
        key: 3,
        desc: "Size",
        checkBoxes: [
          { id: 1, name: "M", checked: false },
          { id: 2, name: "S", checked: false },
          { id: 3, name: "XS", checked: false },
          { id: 4, name: "L", checked: false },
          { id: 5, name: "XL", checked: false },
          { id: 6, name: "XLL", checked: false },
        ],
      },
    ],
    selectedCBs: [],
    clearAllDisplay: "none",
    lowPriceRange: ["Min","100", "200", "300", "400", "500"],
    highPriceRange: ["500+","500", "400", "300", "200", "100"],
  };

  pushSelected = (selectedObj) => {
    this.setState({ selectedCBs: [...this.state.selectedCBs, selectedObj] });
    this.setState({ clearAllDisplay: "block" });
  };

  dontDisplayClearAll = () => {
    if (this.state.selectedCBs.length === 1)
      this.setState({ clearAllDisplay: "none" });
  };

  addSelected = (event) => {
    let cbId = event.target.value;
    let filtId = event.target.id;
    let name;

    //Iterate Filtercomponents and get name
    this.state.filterComponents.map((val) => {
      if (filtId == val.key) {
        val.checkBoxes.map((checkBox) => {
          if (cbId == checkBox.id) {
            name = checkBox.name;
          }
        });
      }
    });

    this.setCheckFlag(filtId, cbId);

    //Create selected object
    let selectedObj = {
      selectedFiltId: filtId,
      selectedCbid: cbId,
      selectedName: name,
    };

    let isSelected = this.filterSelectedCbs(selectedObj);
    //If not selected earlier then add to selectedCBs
    if (!isSelected) {
      this.pushSelected(selectedObj);
    } else {
      this.dontDisplayClearAll();
    }
  };

  //Clears All Checboxes and sets to false
  clearAll = () => {
    this.setState((prevState) => {
      let deltemp = {
        ...prevState,
        filterComponents: [...prevState.filterComponents],
      };
      this.state.filterComponents.map((val) => {
        val.checkBoxes.map((cb) => {
          deltemp.filterComponents[val.key - 1].checkBoxes[
            cb.id - 1
          ].checked = false;
        });
      });
      return deltemp;
    });
    let emptyselectedCBs = [];
    this.setState({ selectedCBs: emptyselectedCBs });
    this.setState({ clearAllDisplay: "none" });
  };

  //Remove object in selectedCBs if the selected object is already present
  filterSelectedCbs(selectedObj) {
    let isSelected;
    this.state.selectedCBs.map((sel) => {
      if (JSON.stringify(sel) === JSON.stringify(selectedObj)) {
        let filteredItems = this.state.selectedCBs.filter(
          (item) => item.selectedName !== selectedObj.selectedName
        );
        isSelected = true;
        this.setState({ selectedCBs: filteredItems });
      }
    });
    return isSelected;
  }
  //Update checked Flag for the selected checkbox
  //If already checked change to unchecked
  setCheckFlag(filtId, cbId) {
    this.setState((prevState) => {
      let temp = {
        ...prevState,
        filterComponents: [...prevState.filterComponents],
      };
      let isChecked =
        temp.filterComponents[filtId - 1].checkBoxes[cbId - 1].checked;
      if (isChecked) {
        temp.filterComponents[filtId - 1].checkBoxes[cbId - 1].checked = false;
      } else {
        temp.filterComponents[filtId - 1].checkBoxes[cbId - 1].checked = true;
      }
      return temp;
    });
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <div className="row">
            <div className="col">
              <label>Filters</label>
            </div>
            <div
              className="col"
              style={{ display: this.state.clearAllDisplay }}
            >
              <label
                className="float-right"
                style={{ color: "#1569C7" }}
                onClick={this.clearAll}
              >
                Clear All
              </label>
            </div>
          </div>
          <Row>
            {this.state.selectedCBs.map((val) => (
              <Col key={val.selectedFiltId + ":" + val.selectedCbid} md="auto">
                <button
                  type="button"
                  className="fa fa-close shadow p-1 rounded"
                  id={val.selectedFiltId}
                  value={val.selectedCbid}
                  onClick={this.addSelected}
                >
                  {val.selectedName}
                </button>
              </Col>
            ))}
          </Row>
        </div>
        <div style={{ marginTop: "10px" }}>
          <label>Categories</label>
          {this.props.categories.map((val) => (
            <div key={val}>
              <span style={{ color: "#555" }}>{val}</span>
            </div>
          ))}
        </div>
        <div style={{ marginTop: "10px" }}>
          <label>Price</label>
          <div className="row">
            <div className="col-4">
              <select name="lowRange" id="lowRange">
                {this.state.lowPriceRange.map((val) => (
                  <option key={val} value={val}>{val}</option>
                ))}
              </select>
            </div>
            <div className="col-4">
            <label>To</label>
            </div>
            <div className="col-4">
            <select name="lowRange" id="lowRange">
                {this.state.highPriceRange.map((val) => (
                  <option key={val} value={val}>{val}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          {this.state.filterComponents.map((val) => (
            <FilterComponent
              addSelected={this.addSelected}
              key={val.key}
              comp={val}
            ></FilterComponent>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default FilterScreen;
